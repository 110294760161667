.container {
    --bs-gutter-x: 0 !important;
    --bs-gutter-y: 0 !important;
    max-width: 100%;
}

.teamCard {
    border: none;
    border-radius: 8px;
}

.teamSubCard {
    margin: 0 auto;
    margin-top: 2rem;
    border: none;
    width: 99%;
    border-radius: 8px;
    padding: 15px !important;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06) !important;
}

.teamSubCard .ant-card-body {
    width: 100%;
    padding: 0 !important;
}

.performanceCard {
    width: 100%;
    min-height: auto;   
    padding-bottom: 1rem;
}
.PerformanceScroll .ant-card-body {
    max-height: 650px !important;
    overflow-y: auto !important;
}
.PerformanceScroll .ant-card-body .ant-card{
    scrollbar-width: none;
}
.PerformanceScroll .ant-card-body .ant-card::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
}
.performanceCard .ant-card {
    padding: 0 !important;
}

.centerProgressCard {
    justify-content: center;
    width: 100%;
}

.teamCard .ant-card .ant-card-head {
    border-bottom: none !important;
}

.countTeamPerformance {
    font-size: 14px;
    border-radius: 8px;
    padding: 5px 10px;
    background: #fafafa;
}

.topPerformanceCard{
    min-height: 400px !important;
    max-height: 600px !important;
    overflow: hidden;
}
.topPerformanceCard .ant-card-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.objectiveText {
    font-size: 20px !important;
    font-weight: 500 !important;
}

.sub-head2 {
    font-size: 14px;
}

.myTeam .ant-menu .ant-menu-title-content .ant-typography,
.menuText {
    color: #454f5b !important;
    font-size: 18px;
    font-weight: 500;
}

.myTeam .ant-menu-light {
    background: none !important;
}

.myTeam .ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected::after {
    border-bottom-color: #0095da !important;
}

.myTeam .ant-menu-horizontal {
    border-bottom: 3px solid rgba(5, 5, 5, 0.06) !important;
}

.myTeam .ant-menu-light.ant-menu-horizontal>.ant-menu-item::after,
.myTeam .ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item::after,
.myTeam .ant-menu-light.ant-menu-horizontal>.ant-menu-submenu::after,
.myTeam .ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu::after {
    border-bottom: 3px solid transparent;
    bottom: -1px !important;
    inset-inline: 2% !important;
}

.ant-menu.ant-menu-horizontal>.ant-menu-item a {
    text-decoration: none;
}

.ant-list-bordered {
    border: none !important;
}

.myTeam .ant-list .ant-list-item {
    padding: 12px 15px;
    border: none !important;
    border-bottom: 2px solid #e9e7e7 !important;
}

.review-status-label {
    font-size: 15px;
    font-weight: 500;
    line-height: 19.5px;
}

.review-comment-date {
    font-size: 12px;
    font-weight: 400;
    color: #637381;
}

.review-comment-text {
    width: 70%;
    font-size: 12px;
    font-weight: 500;
    color: rgb(69, 79, 91, 0.3);
    padding: 10px 15px 10px 15px;
    background: rgb(224, 224, 224, 0.3);
    border-radius: 5px;
}

.right-review-comment-text {
    width: 70%;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    padding: 10px 15px 10px 15px;
    background: #0095DA;
    border-radius: 5px;
}

.plan-review {
    height: 400px;
    background: #ffffff;
    border-radius: 8px;
    padding: 10px 15px 10px 20px;
}

.performance {
    height: 400px;
    background: #ffffff;
    border-radius: 8px;
}

.per-head {
    padding: 10px 20px;
    width: 100%;
}

.per-name {
    padding: 10px 24px 10px 24px;
    border-width: 1px 0px 1px 0px;
    border-style: solid;
    border-color: #e9e7e7;
    height: 40px;
}

.per-name-text {
    font-size: 14px;
    font-weight: 400;
}

.per-score {
    padding: 10px 24px 10px 24px;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: #e9e7e7;
    height: 40px;
}

.per-score-text {
    font-size: 15px;
    font-weight: 600;
}

.per-table {
    height: 40px;
}

.EmpDashBoard {
    margin-top: 2rem;
    flex: none !important;
}

.EmpDashBoard canvas {
    height: 234px !important;
    width: 234px !important;
}

.review-pai-chart .recharts-legend-item-text {
    color: #000000 !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.profileImg {
    border-radius: 100% !important;
    width: 100px !important;
    height: 100px !important;
    margin-top: 0 !important;
    margin-right: 20px !important;
}

.outer-progress-card {
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 8px;
    border: 1px solid #f2f2f2;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06) !important;
    overflow: hidden;
}

.outer-progress-card {
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(235, 235, 235, 1);
}

.inner-progress-card {
    padding-bottom: 7px;
    text-align: center;
}

.inner-progress-card h2 {
    padding: 7px 33px 3px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 0 !important;
}

.inner-progress-card span {
    font-size: 10px;
    padding-top: 10px;
    font-weight: 500;
}

.ipc1,
.ipc2 {
    border-right: 1px solid #f2f2f2;
}

.ipc1 {
    background: #F1D9F2;
}

.ipc1 h2 {
    color: #7B2B7D;
}

.ipc2 {
    background: #ffa30526;
}

.ipc2 h2 {
    color: #ffa305;
}

.ipc3 {
    background: #edf8f4;
}

.ipc3 h2 {
    color: #03BF7A;
}

.ipc4 {
    background: #E7E7FF;
}

.ipc4 h2 {
    color: #7A7DFB;
}

.statusCard {
    background-color: rgb(0, 105, 92, 0.1);
    border-radius: 8px;
}

.statusCard2 {
    background-color: rgb(244, 178, 0, 0.2);
    border-radius: 8px;
}

.bordered {
    border-bottom: 1px solid #e9e7e7;
}

.right-bordered {
    border-right: 1px solid #e9e7e7;
}

.review {
    display: flex;
    justify-content: center;
    align-items: center;
}

.reviewBtn {
    padding: 0 !important;
    --bs-btn-border-radius: none !important;
    --bs-btn-border-color: none !important;
}

.inPlan {
    display: block !important;
    justify-content: left;
    align-items: top;
}

.editIcon {
    display: inline-block;
    margin-top: -100px;
    padding-left: 10%;
}
.editReviewTxt{
    margin-top: -50px;
    font-size: 18px;
    color: #0095DA;
}
.smileIcon{
   font-size: 26px;
}
.imgheart {
    width: 230px !important;
    height: auto;
    vertical-align: middle;
}

.pie-chart-label {
    width: 228px !important;
    display: block;
    margin: 0;
    margin: 15px 0;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
}

.heartPercentageScoreCard {
    margin-top: 1rem;
    width: 220px !important;
    height: auto !important;
    padding: 30px 30px;
    gap: 0px;
    border-radius: 8px;
    background: rgba(250, 250, 250, 1);
}

.chart-text {
    font-size: 36px;
    font-weight: 600;
}

.heartPercentageScoreCard .chart-text {
    font-size: 20px !important;
    font-weight: 500 !important;
}

.chart-subText {
    font-size: 14px !important;
    color: rgba(144, 144, 144, 1) !important;
    font-weight: 500 !important;
}

.imgchart {
    width: 50px !important;
    height: auto;
    vertical-align: middle;
    padding-right: 3px;
}

.greyCard {
    display: inline;
    width: auto;
    background: #fafafa;
    border-radius: 8px;
}

.submittedIcon {
    width: 15px;
    height: 15px;
    border-radius: 8px;
    background: #1a93d3;
    display: inline-block;
    margin-top: 10px;
    margin-right: 5px;
}

.notsubmittedIcon {
    width: 15px;
    height: 15px;
    border-radius: 8px;
    background: #ffa305;
    display: inline-block;
    margin-top: 10px;
}

.viewButton {
    margin-left: 15px;
    font-size: 12px;
    padding: 2px 8px;
    border-color: #1a93d3;
    color: rgba(26, 147, 211, 1);
}

.editIcon {
    display: inline-block;
    margin-right: 5px;
}

.heartPercentage {
    position: absolute;
    top: 112px;
    left: 63%;
    font-size: 40px !important;
}

.poorIcon {
    width: 20px;
    height: 20px;
    background: #ff1313;
    margin-right: 10px;
    border-radius: 5px;
    margin-top: 20px;
    display: inline-block;
}

.averageIcon {
    width: 20px;
    height: 20px;
    background: #ff9900;
    margin-right: 10px;
    border-radius: 5px;
    margin-top: 20px;
    display: inline-block;
}

.goodIcon {
    width: 20px;
    height: 20px;
    background: #03bf7a;
    margin-right: 10px;
    border-radius: 5px;
    margin-top: 20px;
    display: inline-block;
}

.titleColor {
    color: #454f5b !important;
}

.abovePlan {
    width: 15px;
    height: 15px;
    background: #1a93d3;
    margin-right: 10px;
    border-radius: 15px;
    margin-top: 3px;
    display: inline-block;
}

.inPlanTitle {
    width: 15px;
    height: 15px;
    background: #48a9dc;
    margin-right: 10px;
    border-radius: 15px;
    margin-top: 3px;
    display: inline-block;
}

.belowPlan {
    width: 15px;
    height: 15px;
    background: #76bee5;
    margin-right: 10px;
    border-radius: 15px;
    margin-top: 3px;
    display: inline-block;
}

.planTitle {
    font-size: 14px !important;
    color: #454f5b !important;
}

.modal-title {
    padding-left: 20px;
}

.viewall-modal .ant-modal-content{
    min-height: 400px !important;
    width: auto;
}

.viewall-modal .ant-modal-close {
    top: 18px !important;
}

.myCommentSection .ant-card .ant-card-body,
.supervisiorSection .ant-card .ant-card-body {
    display: flex;
    padding: 5px 7px;
}

.supervisiorSection .ant-card .ant-card-body {
    justify-content: end;
    border: rgba(179, 223, 245, 1) !important;
}

.supervisiorSection .ant-card {
    background: rgba(234, 248, 255, 1);
}

.supervisiorSection .commentHeading {
    color: rgba(0, 149, 218, 1);
}

.commentsCard .ant-input {
    border-color: #ededed !important;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 12px 16px;
}

.blur-graph {
    background: rgba(0, 0, 0, 0.1);
    position: relative;
}

.graph-section {
    overflow: scroll;
    scrollbar-width: none;
    position: relative;
    max-height: 350px;
}

.graph-section::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
}

.waitingCard {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.celebration-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 350px;
    height: 320px;
    transform: translate(-50%, -50%);
    z-index: 10;
    overflow: hidden !important;
}


.ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.updatesCard .ant-avatar {
    font-size: 12px !important;
}
.updatesCard{
    overflow: initial !important;
}
.pdfRead .ant-btn>span:hover {
    color: #0095DA;
}

.reviewProgressForm {
    background-color: #f3fbff;
    padding: 20px;
    border-radius: 12px;
    border: 1px solid #9de1ef;
}