.dataTable {
    margin-top: 3rem;
}

.ant-table-content {
    color: #434D5C;
    border: 1px solid #C2CCD6;
    border-radius: 5px;
}

.ant-table-content table{
    table-layout: fixed !important;
}

.userTable .ant-pagination-options-size-changer {
    display: none;
}
.ant-select-single {
    font-size: 16px;
    height: 40px !important;
}
.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-tbody>tr>td {
    padding: 12px 15px;
}

.even-row {
    background-color: none;
}

.odd-row {
    background-color: #FAFAFA;
}

.ant-pagination {
    text-align: end !important;
}

.ant-pagination-total-text {
    color: #607182;
    border: 1px solid #C2CCD6;
    border-radius: 2px;
    height: 43px !important;
    padding: 7px 15px 11px 15px;
}

.ant-pagination .ant-pagination-item {
    color: #607182;
    border: none;
    height: 40px;
    margin-inline-end: 14px !important;
}

.ant-pagination .ant-pagination-item a {
    padding: 4px 14px;
    border: 1px solid #C2CCD6;
    border-radius: 2px;
}
.ant-pagination .ant-pagination-item-active {
    border: none !important;
}

.ant-pagination .ant-pagination-item-active a {
    color: #FAFAFA;
    background: #0088DD;
}

.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):active {
    background-color: #FAFAFA !important;
}

.review-table .ant-table-cell{
    width:400px !important;
    text-align: center !important;
}

.filterDrawer .ant-select-selector{
    min-height: 40px;
}

/* Ensure the container allows overflow */
.container {
    overflow: auto;
    position: relative;
  }

  