.backText {
  color: #0095d5;
  padding-left: 5px;
}
.extensionCard {
  min-height: 500px;
  max-height: auto;
}

.extensionCard .ant-card-head {
  border-bottom: 1px solid rgba(223, 227, 232, 1) !important;
}

.extensionBtn {
  padding: 3px 7px !important;
  border-radius: 8px;
  border-color: rgba(206, 206, 206, 1);
}
.extensionImage {
  margin-top: -2px;
  padding-right: 5px;
}
.ant-table-wrapper .ant-table-cell {
  word-break: normal !important;
}
.reason-column {
  max-width: 350px !important;
}
.reqCount-column,
.goalCount-column {
  max-width: 250px !important;
}

.designation-column,
reviewStatus-column {
  max-width: 200px !important;
  word-break: normal !important;
}

.filterApplied {
  background-color: #198754;
  color: black;
}

.custom-range-picker {
  width: 100%;
  min-width: 250px;
}

.custom-range-picker-dropdown {
  max-width: 300px;
}

.ant-picker-dropdown {
  transform-origin: top center !important; 
}

.ant-picker-range {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ant-picker-range .ant-picker-input {
  flex: 1;
}

.ant-picker-range .ant-picker-separator {
  margin: 0 8px;
}

.no-hover:hover {
  background-color: initial !important;
  color: inherit !important;             
  border-color: inherit !important;     
}

#reportsTable .ant-select-selection-search-input{
  display: none !important;
}