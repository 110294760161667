@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

:root {
  --bs-body-font-weight: 500;
}

body {
  font-family: "IBM Plex Sans", sans-serif;
}

a {
  text-decoration: none !important;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.ant-typography.ant-typography-secondary {
  font-size: 0.8725rem;
  font-weight: 400;
}

.ant-form-item .ant-form-item-extra {
  font-size: 0.8725rem;
  font-weight: 400;
}

.ant-form-item .ant-form-item-explain-error {
  font-weight: 400;
}

.ant-picker-range {
  min-width: 300px;
}

.img-ontrack {
  padding: 0px 7px 0px 0px;
}

.ontrack-text {
  color: #03bf7a;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.0015999999595806003px;
}

.acheivedTxt {
  font-weight: 500;
  color: #00965f;
}

.learnacheivedTxt {
  font-weight: 500;
  color: #e66a41;
}

.targetOuter,
.acheivedOuter {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.0029755241703242064px;
  text-align: left;
}

.targetNos,
.acheivedNos {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.0029755241703242064px;
  text-align: left;
}

.acheivedContain {
  width: -webkit-fill-available;
  height: 53px;
  top: 1014px;
  left: 1282px;
  border-radius: 10px;
  padding: 0px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recruitTargetOuter,
.recruitTargetInner {
  color: #369f7c;
}

.recruitacheived {
  background: #369f7c;
}

.recruitacheivedOuter,
.recruitacheivedInner {
  color: #ffffff;
}

.learnTargetOuter,
.learnTargetInner {
  color: #e66a41;
}

.learnacheived {
  background: #e66a41;
}

.learnacheivedOuter,
.learnacheivedInner {
  color: #ffffff;
}

.inductionTargetOuter,
.inductionTargetInner {
  color: #4f53f8;
}

.inductionacheived {
  background: #4f53f8;
}

.inductionacheivedOuter,
.inductionacheivedInner {
  color: #ffffff;
}

.inductionacheivedTxt {
  font-weight: 500;
  color: #4f53f8;
}

.commentsOnReview {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.0015999999595806003px;
  text-align: center;
}

.hintOncomment {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.0015999999595806003px;
  text-align: left;
  color: #0095da;
}

.supervisorReviewNotescard {
  padding: 20px !important;
  text-align: center;
}

.reviewOuter {
  margin-bottom: 20px;
}

.acheiveEditIcon {
  display: flex;
  align-items: center;
  border: 1px solid #ffffff;
  border-radius: 25px;
  height: 30px;
  width: 30px;
  justify-content: center;
}

.img-acheiveEdit {
  width: 17px;
  height: 17px;
  color: #ffffff;
  cursor: pointer;
}

.reviewCardinnerTitle {
  font-size: 19px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: -0.0017999999690800905px;
  text-align: left;
  color: #454f5b;
}

.viewAllBtn {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.0015999999595806003px;
  text-align: left;
  color: #0a93d5;
  text-decoration: none;
  cursor: pointer;
}

.viewAllOuter {
  justify-content: flex-end;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.reviewOuterCard {
  height: 300px;
  margin: 0px 10px;
  padding: 0px;
}

.Comments-Card.reviewOuterCard {
  max-height: 300px;
  overflow: scroll;
  scrollbar-width: none;
}

.Comments-Card::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.waitingBtn {
  border: none;
  background-color: #ffffff;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.0015999999595806003px;
  text-align: center;
  width: 272px;
  height: 61px;
  border-radius: 8px;
}

.reviewChartcard {
  margin: 0px 10px;
  align-items: center;
  display: flex;
  background: lightgray;
  justify-content: center;
}

.addedCommentCard {
  width: 92%;
  padding: 0px;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.0015999999595806003px;
  text-align: left;
}

.commentHeading {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  margin: 5px 0px;
}

.commentProfileimg {
  width: 13px !important;
  height: 13px !important;
}

.commentProfileName {
  padding: 7px;
}

.password {
  height: 40px !important;
}

.password .ant-input {
  margin-left: 14px !important;
  padding: 3px auto !important;
}

.ant-avatar {
  font-size: 24px !important;
  font-weight: bold !important;
}

.ant-form-item .ant-form-item-explain-error {
  font-size: 14px !important;
}

/* Define scrollbar colors as CSS variables overall brrowser */
:root {
  --scrollbar-thumb-color: #0a93d5; /* Blue color */
  --scrollbar-track-color: #f1f1f1; /* Track color */
}
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
}
body {
  scrollbar-face-color: var(--scrollbar-thumb-color);
  scrollbar-track-color: var(--scrollbar-track-color);
}
.ant-collapse {
  width: 100% !important;
}

//routine color
.routine-color-0 .ant-collapse-header .ant-collapse-header-text {
  color: #ffa305 !important;
}
.routine-color-1 .ant-collapse-header .ant-collapse-header-text {
  color: #7b2b7d !important;
}
.routine-color-2 .ant-collapse-header .ant-collapse-header-text {
  color: #03bf7a !important;
}
.routine-color-3 .ant-collapse-header .ant-collapse-header-text {
  color: #4f53f8 !important;
}

//progress
.routine-color-0 .ant-progress
  .ant-progress-inner:not(.ant-progress-circle-gradient)
  .ant-progress-circle-path  {
  stroke: #ffa305 !important;
}
.routine-color-1 .ant-progress
  .ant-progress-inner:not(.ant-progress-circle-gradient)
  .ant-progress-circle-path  {
  stroke: #7b2b7d !important;
}
.routine-color-2 .ant-progress
  .ant-progress-inner:not(.ant-progress-circle-gradient)
  .ant-progress-circle-path  {
  stroke: #03bf7a !important;
}
.routine-color-3 .ant-progress
  .ant-progress-inner:not(.ant-progress-circle-gradient)
  .ant-progress-circle-path  {
  stroke: #4f53f8 !important;
}

.routine-color-0 .ant-progress .ant-progress-circle-trail {
  stroke: rgb(255, 163, 5, 0.2) !important;
}
.routine-color-1 .ant-progress .ant-progress-circle-trail {
  stroke: rgb(123, 43, 125, 0.2) !important;
}
.routine-color-2 .ant-progress .ant-progress-circle-trail {
  stroke: rgb(3, 191, 122, 0.2) !important;
}
.routine-color-3 .ant-progress .ant-progress-circle-trail {
  stroke: rgb(79, 83, 248, 0.2) !important;
}
.ant-progress.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path{
  stroke: #03BF7A !important;
}

.rupeeClass{
  font-family: 'IndianRupee', Arial, sans-serif !important;
}
.closeIcon{
  font-size: 40px;
  color: red;
}