.ant-select-selector {
    width: 100% !important;
    padding: 0 12px !important;
    margin: auto;
}

.ant-select-single.ant-select-lg.ant-select-show-arrow .ant-select-selection-placeholder {
    font-weight: normal;
}

.ant-select-selector .ant-select-selection-search {
    inset-inline: 12px !important;
}

.ant-select .ant-select-arrow {
    inset-inline-end: 12px;
}

:where(.css-dev-only-do-not-override-o6el5b).ant-select-single .ant-select-selector .ant-select-selection-search {
    inset-inline: 12px;
}

.ant-space-gap-col-small {
    column-gap: span 4;
}

.ant-select-multiple .ant-select-selection-search {
    margin-inline-start: 0px !important;
}

.subHeading {
    font-weight: 500;
}

.ant-table-cell::before {
    display: none;
}

.userSearch {
    margin-top: 10px;
    height: 40px;
    width: 600px;
    border-radius: 15px;
}

.filterBtn {
    height: 38px;
    margin-top: 32px;
}

.profileImg {
    margin: 0 auto;
    border-radius: 100px;
    width: 200px;
    height: auto;
    margin-top: 1em;
}

.blue-text {
    background-color: #0088DD;
    color: aliceblue;
    border-radius: 3px;
    padding: 1px 2px;
}

.reviewLink {
    display: block;
    color: #0088DD;
}

.reviewLink Link {
    text-decoration: underline;
}

.reviewNavtab {
    cursor: pointer;
}

.reviewNavtab:hover {
    color: #0088DD;
}

// form css 
.innerCard .ant-form-item .ant-form-item-control-input {
    min-height: 2px;
}

.formLabel {
    font-size: 16px;
    font-weight: 500;
}

.formInput {
    height: 40px;
}

.innerCard .ant-select-selector {
    width: 100% !important;
}

.empFormBtn {
    height: 35px;
    padding: 4px 93px;
}