.ant-typography + h1.ant-typography {
  margin-top: 1rem !important;
  margin-bottom: 0 !important;
}

h4,
h2,
h3,
h5 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 0;
}

.adminHead {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 31.2px !important;
  letter-spacing: 0.005400000140070915px !important;
}
.adminSubHead {
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 26px !important;
  letter-spacing: 0.005400000140070915px !important;
}

.adminSecHead {
  font-family: IBM Plex Sans;
  font-size: 17.26px !important;
  font-weight: 500 !important;
  line-height: 22.44px !important;
}

.adminCountTxt {
  font-family: IBM Plex Sans;
  font-size: 34.52px !important;
  font-weight: 500 !important;
  line-height: 44.87px !important;
}

// Cards
.dashboard-card {
  -webkit-box-shadow: 1px 6px 7px 4px rgba(206, 215, 222, 1);
  -moz-box-shadow: 1px 6px 7px 4px rgba(206, 215, 222, 1);
  box-shadow: 1px 6px 7px 4px rgba(206, 215, 222, 1);
  height: 188px;
  text-align: center;
}

.dashboardCard {
  height: 250px;
}

.userCard {
  text-align: center;
  background: linear-gradient(324deg, rgba(243, 250, 253, 1) 50%, #ffffff 50%);
}
.dashboardChart  .ant-progress-circle-trail {
  stroke-width: 27px !important;
}

.dashboardChart {
  height: 100% !important;
  width: auto !important
}
.dashboardChart .ant-progress-inner{
  width: 135px !important;
  height: 135px !important;
}

.dashboardChart{
  text-align: center;
  padding-top: 10px;
}
.bluCircle {
  width: 17px;
  height: 17px;
  border-radius: 100px;
  background: rgba(10, 147, 213, 1);
}

.yellowCircle {
  width: 17px;
  height: 17px;
  border-radius: 100px;
  background: rgba(241, 179, 67, 1);
}

.countSection {
  width: 100%;
  margin-top: 40px;
}
.gifCard {
  position: relative;
}

.reviewContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.grapghCard .ant-card-head{
  padding: 20px 20px 14px 20px;
  background-color: rgba(243, 250, 253, 1);
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
}

.graph-column{
  text-align: center;
  padding-bottom: 25px;
  padding-right: 20px;
}

.targetGifDashboard{
  position: absolute;
  top: -23px;
  left: -12px;
}

.dashboardCard{
  .ant-progress.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: rgb(10, 147, 213) !important;  
  }
}