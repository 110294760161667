.backNavigation Link {
  color: #0088dd;
  font-weight: 600;
}

.container {
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
}

.ant-card:not(.header-border) .ant-card-head {
  border-bottom: none;
}

.ant-btn:not(.ant-btn-icon-only) > .ant-btn-icon:not(:last-child) {
  margin-inline-end: 12px;
}

.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
  background: none;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom-width: 4px;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 4px;
}

.topTitleCard {
  width: 100%;
  height: auto;
  text-align: center;
  padding: 10px 8px;
  background: linear-gradient(to right, #e0f2ff 0%, #ebf1e5 50%, #fff4e0 100%);
}

.targetGif {
  height: 35px !important;
  width: auto;
  padding-right: 10px;
}

.boldTxt {
  font-weight: 600;
}

.blueTxt {
  color: #0088dd;
}

.greyTxt {
  color: #454f5b !important;
}

.profileCard {
  justify-content: center !important;
}

.performanceCard .ant-image .profileImg {
  border-radius: 100% !important;
  width: 100px !important;
  height: 100px !important;
  margin-top: 0 !important;
  margin-right: 20px !important;
}

.copyIcon .ant-typography-copy {
  float: left;
  margin-inline: 0 8px;
}

.designation {
  color: #637381;
  margin-bottom: 5px !important;
}

.subContent {
  padding: 15px;
  padding-left: 30px;
  border-left: 1.5px solid #e9e7e7;
}

.head1 {
  font-size: 16px;
  font-weight: 500;
  color: #454f5b;
  display: block;
  padding-bottom: 5px;
}

.sub-head1 {
  color: #637381;
  font-size: 12px;
}

.head2 {
  font-size: 16px;
  color: #000000;
  font-weight: 400;
}

.timelineCard {
  margin-top: 5rem !important;
  padding: 0 !important;
}

.timeLineTitle {
  color: #454f5b !important;
  margin: 20px auto auto 20px !important;
}

.timelineCard hr {
  border-top: 2px solid #dfe3e8;
}

.stepContainer {
  padding: 30px 50px 30px 70px;
  width: auto;
  overflow-x: scroll;
}

.stepContainer::-webkit-scrollbar {
  height: 5px;
  width: thin;
}

.stepContainer::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.stepContainer::-webkit-scrollbar-thumb:hover {
  background-color: rgb(10, 147, 213);
}

.stepContainer::-moz-scrollbar-thumb:active {
  background-color: rgb(10, 147, 213);
}

.stepContainer::-moz-scrollbar-button:hover {
  background-color: rgb(10, 147, 213);
}

.stepContainer::-moz-scrollbar-button:active {
  background-color: rgb(10, 147, 213);
}

.ant-steps {
  width: 2265px !important;
}

.timeLineImg {
  width: 55px !important;
  height: auto;
}

.waitImg {
  width: 23px !important;
  height: auto;
  padding-top: 18px;
  padding-bottom: 15px;
}

.ant-steps.ant-steps-label-vertical .ant-steps-item-content {
  margin-left: -75px;
  margin-top: 18px;
  width: 280px;
}

.ant-steps-item-description {
  font-size: 0.8rem !important;
  font-weight: 400;
}

.ant-steps-label-vertical .ant-steps-item-tail {
  margin-inline-start: 56px;
  padding: 27px 0px !important;
}

.ant-steps .ant-steps-item-tail::after {
  height: 8px !important;
}

.ant-steps
  .ant-steps-item-process
  .ant-steps-item-container
  .ant-steps-item-tail::after {
  background: linear-gradient(to right, #ffa305 50%, #c4cdd5 50%);
}

.ant-steps
  .ant-steps-item-wait
  .ant-steps-item-container
  .ant-steps-item-tail::after {
  background-color: #c4cdd5 !important;
}

.ant-steps
  .ant-steps-item-finish
  .ant-steps-item-container
  .ant-steps-item-tail::after {
  background-color: #03bf7a;
}

.ant-steps
  .ant-steps-item-custom
  > .ant-steps-item-container
  > .ant-steps-item-icon
  > .ant-steps-icon {
  top: 17px !important;
}

.readLink {
  color: #666666 !important;
}

.menuCard {
  border-radius: 8px;
  width: 100%;
}

.menuCard .ant-card-body {
  padding: 0 0 15px 0 !important;
}

.menuCard .ant-card .ant-card-head {
  border-bottom: 0px !important;
}

.menuCard .listItems {
  height: 200px;
  overflow: scroll;
  scrollbar-width: none;
}

.listItems::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.menuListImage {
  padding-top: 5px;
  padding-right: 12px;
}

.menuCard .ant-menu-horizontal {
  border-bottom: 4px solid rgba(5, 5, 5, 0.06);
}

.menuCard .ant-menu .ant-menu-title-content .ant-typography,
.menuText {
  color: #454f5b !important;
  font-size: 18px;
  font-weight: 500;
}

.menuCard .ant-menu-light {
  background: white !important;
  border-radius: 8px 8px 0 0;
}

.menuCard .ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
  border-bottom-color: #0095da !important;
  bottom: -3px !important;
  width: 100%;
}

.menuCard .ant-menu-light .ant-menu-item {
  width: 49% !important;
}

.menuCard .ant-menu-horizontal .ant-menu-item,
.menuCard .ant-menu-horizontal .ant-menu-submenu {
  padding-inline: 14% !important;
}

.menuCard .ant-menu-light.ant-menu-horizontal > .ant-menu-item::after,
.menuCard
  .ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-item::after,
.menuCard .ant-menu-light.ant-menu-horizontal > .ant-menu-submenu::after,
.menuCard
  .ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-submenu::after {
  border-bottom: 4px solid transparent;
  inset-inline: 2% !important;
}

.ant-list-bordered {
  border: none !important;
}

.menuCard .ant-list .ant-list-item {
  justify-content: space-between;
  padding: 12px 15px;
  border: none !important;
  border-bottom: 2px solid #e9e7e7 !important;
}

.review-menu .ant-menu-horizontal {
  border-bottom: 4px solid rgba(5, 5, 5, 0.06);
}

.supervisorMenu {
  width: 100%;

  .ant-menu-item {
    color: black;

    &.ant-menu-item-selected {
      color: black !important;
    }

    &:hover {
      color: black !important;
    }
  }
}

.review-menu .ant-menu-light {
  background: transparent !important;
}

.review-menu
  .ant-menu-light.ant-menu-horizontal
  > .ant-menu-item-selected::after {
  border-bottom: 4px solid #0095da !important;
}

.ant-tag {
  margin-inline-end: 0px !important;
}

.dateTxt {
  color: #637381;
  display: block;
  font-weight: 500;
}

.ant-tag {
  color: #637381;
  background: #fbfbfb;
  border-radius: 20px;
  padding: 5px 15px;
  font-size: 14px;
  border: none;
  font-weight: 500;
}

.ant-tag.ant-tag-success {
  color: #00ce7f;
  background: rgb(0, 206, 127, 0.1);
}

.ant-tag.ant-tag-warning {
  color: #ffa305;
  background: #ffa30526;
}

.model {
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  width: 393px !important;
}

.modal-right {
  top: 0;
  right: 0;
  position: absolute;
  min-height: 100vh;
  padding-bottom: 0 !important;
}

.ant-modal .ant-modal-content {
  padding: 20px 24px;
}

.ant-modal.modal-right .ant-modal-content {
  border-radius: 0;
  height: 100vh;
  padding: 20px 0;
}

.ant-modal.modal-right .ant-modal-header {
  padding: 0 24px;
}

.ant-modal.modal-right .ant-modal-footer {
  padding: 20px 24px;
  margin: 0;
  box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.06);
}

.ant-modal.modal-right .ant-modal-close {
  top: 20px;
  inset-inline-end: 24px;
}

.ant-modal.modal-right .ant-modal-body {
  min-height: calc(100vh - 154px);
  max-height: calc(100vh - 154px);
  overflow-x: scroll;
  scrollbar-width: none;
  padding: 0 24px;
}

.ant-modal.modal-right.no-footer .ant-modal-body {
  min-height: calc(100vh - 74px);
  max-height: calc(100vh - 74px);
}

.ant-modal.modal-right .ant-modal-body::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.review-progress.ant-collapse-ghost > .ant-collapse-item {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.review-progress.ant-collapse.ant-collapse-icon-position-end
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-expand-icon {
  height: 28px;
  padding-inline-start: 14px;
}

.review-progress.ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 12px 16px;
}

.ant-radio-group.tag-selection .ant-radio-button-wrapper {
  padding-block: 6px;
  border-radius: 14px;
  line-height: 1;
  height: auto;
  margin: 6px;
  border-width: 1px;
  font-size: 0.8rem;
}

.tag-selection
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.yet-to-start {
  background: #f1d9f2;
  border-color: #7b2b7d;
  color: #7b2b7d;
}

.tag-selection
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.in-progress {
  background: #ffa30526;
  border-color: #ffa305;
  color: #ffa305;
}

.tag-selection
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.on-hold {
  background: #e7e7ff;
  border-color: #7a7dfb;
  color: #7a7dfb;
}

.tag-selection
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.completed {
  background: #e1ede9;
  border-color: #03bf7a;
  color: #03bf7a;
}

.tag-selection .ant-radio-button-wrapper::before {
  display: none;
}

.ant-drawer .ant-drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
}

.ant-drawer .ant-drawer-close {
  order: 2;
  margin: 0;
}

.review-model {
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  width: 750px !important;
}

.review-model .ant-modal-body {
  height: auto;
  min-height: 400px !important;
}

.confirm .ant-modal-content {
  padding: 0px 10px !important;
  width: 390px;
  height: 205px;
}

.alert .ant-modal-content {
  padding: 10px 10px !important;
  width: 390px;
  height: 156px;
}

.left-btn {
  width: 410px;
  margin-left: -52px;
}

.right-btn {
  width: 200px;
}

.confirm-btn {
  margin-left: 10px;
}

.model .ant-modal-content {
  padding: 30px 30px !important;
}

.model .ant-modal-body {
  min-height: 437px !important;
}

.goal-model {
  min-height: 437px !important;
}

.model-select {
  width: 104% !important;
}

.modal-label {
  font-size: 16px;
  font-weight: 500;
  color: #454f5b;
}

.formInputItem .ant-select-selection-item {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.formInputItem .ant-input {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.formInputItem .ant-input-number-input {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.review-card .ant-card-body {
  padding: 0 !important;
}

.recruitment .ant-collapse-header-text {
  color: #48ab8a !important;
  font-size: large;
  font-weight: 500;
}

.learning .ant-collapse-header-text {
  color: #ffa305 !important;
  font-size: large;
  font-weight: 500;
}

.induction .ant-collapse-header-text {
  color: #7a7dfb !important;
  font-size: large;
  font-weight: 500;
}

.review-card .ant-collapse-arrow {
  font-size: 18px !important;
}

.review-card .ant-collapse-header {
  padding: 10px 10px !important;
}

.updateCard .ant-progress .ant-progress-inner {
  height: 47px;
  border-radius: 6px;
  background: #dddddd;
}

.updateCard .ant-progress .ant-progress-bg {
  background: #ffd69b;
  height: 47px !important;
  border-radius: 0 !important;
}

.model {
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.review-model {
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  width: 750px !important;
}

.review-model .ant-modal-body {
  height: auto;
  min-height: 400px !important;
}

.confirm .ant-modal-content {
  padding: 0px 10px !important;
  margin: 0 auto !important;
  width: 390px;
  height: 205px;
}

.alert .ant-modal-content {
  padding: 10px 10px !important;
  width: 390px;
  height: 156px;
}

.approval .ant-modal-content {
  padding: 18px 18px !important;
  width: 545px;
  min-height: auto;
}
.confirmationSecomdaryText {
  padding: 10px;
  text-align: center;
  font-size: 20px;
  color: rgb(69, 79, 91, 0.7);
}

.right-btn {
  width: 200px;
}

.confirm-btn {
  margin-left: 10px;
}

.model .ant-modal-content {
  padding: 30px 30px !important;
  border-radius: 0;
  min-height: 100vh !important;
  max-height: auto !important;
}

.model .ant-modal-body {
  min-height: 437px !important;
}

.goal-model {
  min-height: 437px !important;
}

.modal-label {
  font-size: 16px;
  font-weight: 500;
  color: #454f5b;
}

.formInputItem .ant-select-selection-item {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.formInputItem .ant-input {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.formInputItem .ant-input-number-input {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.review-card .ant-card-body {
  padding: 0 !important;
}

.recruitment .ant-collapse-header-text {
  color: #48ab8a !important;
  font-size: large;
  font-weight: 500;
}

.learning .ant-collapse-header-text {
  color: #ffa305 !important;
  font-size: large;
  font-weight: 500;
}

.induction .ant-collapse-header-text {
  color: #7a7dfb !important;
  font-size: large;
  font-weight: 500;
}

.review-card .ant-collapse-arrow {
  font-size: 18px !important;
}

.review-card .ant-collapse-header {
  padding: 10px 10px !important;
}

.tag {
  cursor: pointer;
  padding: 10px;
  border-radius: 28px;
  background: #ffffff;
  border: 1px solid rgba(99, 115, 129, 1);
}

.greenTag {
  background: rgba(72, 171, 138, 1) !important;
  border: none !important;
  color: #ffffff !important;
}

.orgTag {
  background: rgba(255, 163, 5, 1) !important;
  border: none !important;
  color: #ffffff !important;
}

.redTag {
  background: rgba(254, 138, 100, 1) !important;
  border: none !important;
  color: #ffffff !important;
}

.tag span {
  padding: 10px;
}
.groupRadio .ant-radio-button-wrapper {
  cursor: pointer;
  padding: auto 20px !important;
  border-radius: 50px !important;
  background: #ffffff;
}
.groupRadio .ant-radio-button-wrapper-checked {
  padding: auto 20px !important;
  border: none !important;
}
.target-text {
  font-size: large;
}

.achived-imput {
  width: 100px;
}

.text {
  border: 1px solid #ececec;
  border-radius: 8px;
  padding: 10px 15px 10px 15px;
  width: 307px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
}

.super-text {
  border: 1px solid #b3dff5;
  border-radius: 16px;
  padding: 10px 15px 10px 15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  background: #eaf8ff;
}

.comment-title {
  color: #0095da !important;
  font-size: 18px !important;
  text-align: end;
}

.comment-section {
  border-bottom: 1px dotted #888;
  // padding: 10px 10px;
}

.tag {
  cursor: pointer;
  padding: 5px 5px 5px 5px;
  border-radius: 16px;
}

.target-text {
  font-size: large;
}

.achived-imput {
  width: 100px;
}

.text {
  border: 1px solid #ececec;
  border-radius: 8px;
  padding: 10px 15px 10px 15px;
  width: 307px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
}

.comment-title {
  color: #0095da !important;
  font-size: 18px !important;
  text-align: end;
}

.comment-section {
  border-bottom: 1px dotted #888;
  // padding: 10px 10px;
}

.submit_btn {
  border: 1px solid #0a93d5;
  padding: 0px 20px;
  margin-right: 10px;
  font-size: 14px;
  color: #0a93d5;
  font-weight: 600;
  border-radius: 5px;
  box-shadow: none;
}
.golasCardScroll .ant-card-body {
  min-height: auto;
  max-height: 701px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.commentsScroll .ant-card-body {
  min-height: auto !important;
  max-height: 350px !important;
  overflow-y: auto !important;
  scrollbar-width: thin;
}

.golasCardScroll .ant-card-body::-webkit-scrollbar,
.commentsScroll .ant-card-body::-webkit-scrollbar {
  width: 5px;
}

.golasCardScroll .ant-card-body::-moz-scrollbar,
.commentsScroll .ant-card-body::-moz-scrollbar {
  width: 5px;
}

.golasCardScroll .ant-card-body::-ms-scrollbar,
.commentsScroll .ant-card-body::-ms-scrollbar {
  width: 5px;
}
.add_btn {
  background-color: #0a93d5;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  box-shadow: none;
}

.recruit_text {
  color: #48ab8a;
  font-size: 18px;
  font-weight: 600;
}

.description {
  color: #636262;
  font-size: 15px;
}

.description_list {
  font-size: 16px;
  font-weight: 500;
}

.step1 {
  margin-left: 1rem;
  width: 98% !important;
}

.step-card {
  max-height: 700px;
  overflow: scroll;
  scrollbar-width: none;
}

.goalText {
  color: #454f5b !important;
}

.step1-align {
  text-align: right;
}

.step1-align-center {
  margin: auto 10px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.img-objective {
  margin: 0 auto;
  width: 220px !important;
  margin-top: 1.5rem !important;
  margin-bottom: 1rem;
}

.goalsContent {
  font-size: 20px;
  margin: 0px -41px;
}

.ant-typography + h5.ant-typography {
  font-weight: 500;
}

.img-edit {
  width: 15px;
  margin-bottom: 7px;
  margin-right: 7px;
}

.edit-text {
  color: #000000;
  opacity: 0.5;
}

.postApprovalcard .ant-card .ant-card-body {
  padding: 0 !important;
}

.learning_text {
  color: #ffa305;
  font-size: 18px;
  font-weight: 600;
}

.induction_text {
  color: #7a7dfb;
  font-size: 18px;
  font-weight: 600;
}

.text-number {
  color: #48ab8a;
  font-size: 24px;
  font-weight: 600;
}

.text-display {
  margin-top: -19px;
  text-align: right;
}

.text-number1 {
  color: #ffa305;
  font-size: 24px;
  font-weight: 600;
}

.text-number2 {
  color: #7a7dfb;
  font-size: 24px;
  font-weight: 600;
}

.text-nos {
  color: #48ab8a;
  font-size: 25px;
  font-weight: 600;
}

.text-nos1 {
  color: #ffa305;
  font-size: 25px;
  font-weight: 600;
}

.text-nos2 {
  color: #7a7dfb;
}

.submit_btn {
  border: 1px solid #0a93d5;
  padding: 0px 20px;
  margin-right: 10px;
  font-size: 14px;
  color: #0a93d5;
  font-weight: 600;
  border-radius: 5px;
  box-shadow: none;
}

.add_btn {
  background-color: #0a93d5;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  box-shadow: none;
}

.recruit_text {
  color: #48ab8a;
  font-size: 18px;
  font-weight: 600;
}

.description {
  color: #636262;
  font-size: 15px;
}

.description_list {
  font-size: 16px;
  font-weight: 500;
}

.step1 {
  margin-left: 1rem;
  width: 98% !important;
}

.step-card {
  max-height: 700px;
  overflow: scroll;
  scrollbar-width: none;
}

.step-card::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.step-card .ant-card {
  margin: 10px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06) !important;
}

.goalText {
  color: #454f5b !important;
}

.step1-align {
  text-align: right;
}

.step1-align-center {
  margin: auto 10px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.img-objective {
  margin: 0 auto;
  width: 220px !important;
  margin-top: 1.5rem !important;
  margin-bottom: 1rem;
}

.goalsContent {
  font-size: 20px;
  margin: 0px -41px;
}

.ant-typography + h5.ant-typography {
  font-weight: 500;
}

.img-edit {
  width: 15px;
  margin-bottom: 7px;
  margin-right: 7px;
}

.edit-text {
  color: #000000;
  opacity: 0.5;
}

.postApprovalcard .ant-card .ant-card-body {
  padding: 0 !important;
}

.learning_text {
  color: #ffa305;
  font-size: 18px;
  font-weight: 600;
}

.induction_text {
  color: #7a7dfb;
  font-size: 18px;
  font-weight: 600;
}

.text-number {
  color: #48ab8a;
  font-size: 24px;
  font-weight: 600;
}

.text-display {
  margin-top: -19px;
  text-align: right;
}

.text-number1 {
  color: #ffa305;
  font-size: 24px;
  font-weight: 600;
}

.text-number2 {
  color: #7a7dfb;
  font-size: 24px;
  font-weight: 600;
}

.text-nos {
  color: #48ab8a;
  font-size: 25px;
  font-weight: 600;
}

.text-nos1 {
  color: #ffa305;
  font-size: 25px;
  font-weight: 600;
}

.text-nos2 {
  color: #7a7dfb;
  font-size: 25px;
  font-weight: 600;
  margin-left: 0px;
}

.custom-tooltip {
  border-radius: 8px;
}

.custom-tooltip .ant-tooltip-inner {
  height: auto;
  max-height: 130px;
  border-radius: 8px;
  padding: 9px 15px !important;
  background-color: white;
  color: #000000;
  overflow: scroll;
  scrollbar-width: none;
}

.custom-tooltip .ant-tooltip-inner::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.toolTipText {
  margin-left: 10px;
}

.custom-tooltip .ant-tooltip-arrow:before {
  background: white;
}

.ant-float-btn.float-btn .ant-float-btn-body {
  background: linear-gradient(180deg, #0a93d5 0%, #006cba 100%);
}

.ant-float-btn-body {
  width: 140px !important;
}

.ant-float-btn .ant-float-btn-body .ant-float-btn-content {
  width: 140px !important;
  height: 120px !important;
  padding: 10px;
}

.ant-float-btn-icon {
  width: 122px !important;
}

.float-progress .ant-progress-text {
  color: #fff !important;
}

.float-text {
  color: #fff !important;
}

.review-title {
  color: #637381 !important;
}

.comments {
  font-size: 18px !important;
  font-weight: 600;
}

.alert-container {
  position: fixed;
  top: 50px;
  /* Adjust this value to position the alert vertically */
  left: 50%;
  /* Center horizontally */
  transform: translateX(-50%);
  z-index: 9999;
  /* Ensure the alert appears on top */
}

.ant-message-notice-content {
  background-color: lightcyan;
  margin-top: 60px;
}

.ant-message-notice .ant-message-success {
  font-size: 18px;
  line-height: 2;
}

.ant-message-notice .anticon {
  font-size: 22px !important;
}

.review-menu {
  background: transparent !important;
}

.ant-list .ant-list-item.goal-item {
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.03);
  border: none;
  border-radius: 6px;
  margin-bottom: 24px;
  padding: 0;
}

.goal-item .achieved {
  border-radius: 0 0 6px 6px;
  background: #e7e7ff;
}

.goal-item.success .achieved {
  background: #e1ede9;
}

.goal-item.exception .achieved {
  background: #f1d9f2;
}

.goal-item.active .achieved {
  background: #ffa30526;
}

.goal-item .ant-statistic.smart {
  border-radius: 6px;
  background: #7a7dfb;
}

.goal-item.success .ant-statistic.smart {
  background: #03bf7a;
}

.goal-item.exception .ant-statistic.smart,
.goal-item.exception .ant-progress .ant-progress-bg {
  background: #7b2b7d;
}

.goal-item.active .ant-statistic.smart,
.goal-item.active .ant-progress .ant-progress-bg {
  background: #ffa305;
}

.goal-item .ant-statistic .ant-statistic-title {
  margin: 0;
  font-weight: 400;
}

.goal-item .ant-typography.title,
.goal-item .ant-statistic .ant-statistic-title,
.goal-item .ant-statistic .ant-statistic-content-value,
.goal-item .achieved .ant-typography,
.goal-item .ant-statistic .ant-statistic-content-suffix {
  color: #4f53f8;
}

.goal-item.success .ant-typography.title,
.goal-item.success .ant-statistic .ant-statistic-content-value,
.goal-item.success .ant-statistic .ant-statistic-title,
.goal-item.success .achieved .ant-typography,
.goal-item.success .ant-statistic .ant-statistic-content-suffix {
  color: #03bf7a;
}

.goal-item.exception .ant-typography.title,
.goal-item.exception .ant-statistic .ant-statistic-content-value,
.goal-item.exception .ant-statistic .ant-statistic-title,
.goal-item.exception .achieved .ant-typography,
.goal-item.exception .ant-statistic .ant-statistic-content-suffix {
  color: #7b2b7d;
}

.goal-item.active .ant-typography.title,
.goal-item.active .ant-statistic .ant-statistic-content-value,
.goal-item.active .ant-statistic .ant-statistic-title,
.goal-item.active .achieved .ant-typography,
.goal-item.active .ant-statistic .ant-statistic-content-suffix {
  color: #ffa305;
}

.goal-item .ant-statistic-content .ant-statistic-content-suffix {
  font-size: 1rem;
  margin-inline-start: 4px;
}

.goal-item .ant-statistic.smart .ant-statistic-title,
.goal-item .ant-statistic.smart .ant-statistic-content-value,
.goal-item .ant-statistic.smart .ant-statistic-content-suffix {
  color: #fff;
}

.goal-item .ant-btn.iconEdit {
  position: absolute;
  background: transparent;
  border: none;
  top: 20px;
  right: 25px;
}

.goal-item .ant-btn.iconEdit:hover {
  background: transparent;
}

.ant-list.commentList .commentItem {
  border-block-end: none;
  clear: both;
}

.ant-list.commentList .commentItem.right {
  float: right;
  text-align: end;
}

.ant-list.commentList .commentItem.right .title {
  color: #0095da;
}

.ant-list.commentList .commentItem .comment {
  border: 1px solid #ececec;
  border-radius: 6px;
}

.ant-list.commentList .commentItem.right .comment {
  background: #eaf8ff;
  border-color: #b3dff5;
}

.ant-message-notice .anticon {
  font-size: 22px !important;
}

.review-menu {
  background: transparent !important;
}

.remainingCountTag {
  margin-right: 20px;
  border-radius: 0px !important;
}

.reasonRejectItem{
  width: 90%;
  margin: 0 auto;
  margin-top: 10px;
}